import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll';
import {useTranslation} from "react-i18next";
import logo_dark from '../assets/images/serbia/logo.png';
import vivid from '../assets/images/serbia/vivid.png';
import slide from '../assets/images/serbia/slide.jpg';
import facebook from '../assets/images/serbia/facebook.png';
import instagram from '../assets/images/serbia/instagram.png';
import serbia from '../assets/images/serbia/serbia.png';
import video_src from '../assets/images/serbia/video.mp4';
import video_poster from '../assets/images/serbia/video.jpg';
import avatar from '../assets/images/serbia/avatar.jpg';

import car from '../assets/images/serbia/car.png';
import phone1 from '../assets/images/serbia/phone1.png';
import phone2 from '../assets/images/serbia/phone2.png';
import dataPic from '../assets/images/serbia/data-pic.png';

import aIcon1 from '../assets/images/serbia/a-icon-1.png';
import aIcon2 from '../assets/images/serbia/a-icon-2.png';
import aIcon3 from '../assets/images/serbia/a-icon-3.png';
import aIcon4 from '../assets/images/serbia/a-icon-4.png';
import bIcon1 from '../assets/images/serbia/b-icon-1.png';
import bIcon2 from '../assets/images/serbia/b-icon-2.png';
import bIcon3 from '../assets/images/serbia/b-icon-3.png';
import bIcon4 from '../assets/images/serbia/b-icon-4.png';
import bIcon5 from '../assets/images/serbia/b-icon-5.png';
import bIcon6 from '../assets/images/serbia/b-icon-6.png';
import cIcon1 from '../assets/images/serbia/c-icon-1.png';
import cIcon2 from '../assets/images/serbia/c-icon-2.png';
import cIcon3 from '../assets/images/serbia/c-icon-3.png';
import cIcon4 from '../assets/images/serbia/c-icon-4.png';

import carIcon from '../assets/images/serbia/car-icon.png';
import langIcon from '../assets/images/serbia/lang-icon.png';
import shopIcon from '../assets/images/serbia/shop-icon.png';
import indexIcon from '../assets/images/serbia/index-icon.png';
import srIcon from '../assets/images/serbia/sr.png';
import cnIcon from '../assets/images/serbia/cn.png';
import enIcon from '../assets/images/serbia/us.png';
import talIcon from '../assets/images/serbia/tal-icon.png';

import { Player } from 'video-react';
import '../assets/css/serbia-index.css'
import "../../node_modules/video-react/dist/video-react.css";
import {  Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { useNavigate } from 'react-router-dom';

export default function SerbiaIndex() {
    const { t } = useTranslation();
    const [isOpen, setMenu] = useState(false);
    const [langOpen, setLangOpen] = useState(false);
    const toggleMenu = () => {
        setMenu(!isOpen)
    }
    const toggleLang = () => {
        setLangOpen(!langOpen)
        console.log(langOpen)
    }
    const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const navigate = useNavigate();
    return (
        <>
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
                <div className={`${isOpen === true ? 'menu-collapse is-open' : 'menu-collapse is-close'}`} id="">
                    <div className='menu-close' onClick={toggleMenu}></div>
                    <div className="menu-content">
                        <ul className="navbar-menu" >
                            <li className={`nav-menu-item`}>
                            <Link 
                                to="home" 
                                spy={true} 
                                smooth={true} 
                                className="nav-link"
                                onClick={toggleMenu}
                            >
                                    <img src={indexIcon} alt="" />
                                    <span>{t('home')}</span>
                                </Link>
                            </li>
                            <li className={`nav-menu-item`}>
                            <Link 
                                to="section1" 
                                spy={true} 
                                smooth={true} 
                                className="nav-link"
                                onClick={toggleMenu}
                            >
                                    <img src={shopIcon} alt="" />
                                    <span>{t('Vivid_Wash')}</span>
                                </Link>
                            </li>
                            <li className={`nav-menu-item`}>
                            <Link 
                                to="section2" 
                                spy={true} 
                                smooth={true} 
                                className="nav-link"
                                onClick={toggleMenu}
                            >
                                    <img src={carIcon} alt="" />
                                    <span>{t('Vivid_Wash_Owner')}</span>
                            </Link>
                            </li>
                            <li className={`nav-menu-item`} onClick={toggleLang}>
                                <span className="nav-link">
                                    <img src={langIcon} alt="" />
                                    {i18n.language === "en" && <span>English</span>}
                                    {i18n.language === "sr" && <span>српски</span>}
                                    {i18n.language === "zh" && <span>简体中文</span>}
                                </span>
                            </li>
                            <li 
                                onClick={() => navigate('/')}
                                className="nav-menu-item"
                            >
                                <span className="nav-link">
                                    <img src={talIcon} alt="" />
                                    <span >{t('Tailwind Trade')}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`${langOpen === true ? 'menu-collapse is-open' : 'menu-collapse is-close'}`} id="">
                    <div className='menu-back' onClick={toggleLang}></div>
                    <div className="menu-content">
                        <ul className="navbar-menu navbar-menu-lang" >
                            <li
                                onClick={() => changeLanguage("en")}
                                className={`nav-menu-item ${i18n.language === "en" ? "active" : ""}`}
                            >
                                <span className='nav-link'>English</span>
                                <span className='uil uil-check'></span>
                            </li>
                            <li
                                onClick={() => changeLanguage("zh")}
                                className={`nav-menu-item ${i18n.language === "zh" ? "active" : ""}`}
                            >
                                <span className='nav-link'>简体中文</span>
                                <span className='uil uil-check'></span>
                            </li>
                            <li 
                                onClick={() => changeLanguage("sr")}
                                className={`nav-menu-item ${i18n.language === "sr" ? "active" : ""}`}
                            >
                                <span className='nav-link'>српски</span>
                                <span className='uil uil-check'></span>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <div className="pc-lang">
                    <div className="lang-button">
                        {i18n.language === "en" && (<><img src={enIcon} alt="" /><span>English</span></>)}
                        {i18n.language === "sr" && (<><img src={srIcon} alt="" /><span>српски</span></>)}
                        {i18n.language === "zh" && (<><img src={cnIcon} alt="" /><span>简体中文</span></>)}
                    </div>
                    <div className="pc-menu-lang">
                        <ul className="pc-lang-list" >
                            <li
                                onClick={() => changeLanguage("en")}
                                className={`li-item ${i18n.language === "en" ? "active" : ""}`}
                            >
                                <img src={enIcon} alt="" />
                                <span >English</span>
                            </li>
                            <li
                                onClick={() => changeLanguage("zh")}
                                className={`li-item ${i18n.language === "zh" ? "active" : ""}`}
                            >
                                <img src={cnIcon} alt="" />
                                <span >简体中文</span>
                            </li>
                            <li 
                                onClick={() => changeLanguage("sr")}
                                className={`li-item ${i18n.language === "sr" ? "active" : ""}`}
                            >
                                <img src={srIcon} alt="" />
                                <span >српски</span>
                            </li>

                        </ul>
                    </div>
                </div>

                <div className="serbia-index">
                    <nav className="nav-navbar fixed z-50">
                        <div className="nav-container flex items-center justify-between">
                            <div className="logo-container flex items-center">
                                <img src={logo_dark}   className="l-dark " alt="" />
                            </div>
                            <div className="navbar-nav flex items-center space-x-12">
                                <Link 
                                    to="home" 
                                    spy={true} 
                                    smooth={true} 
                                    className="nav-item text-medium"
                                >
                                     {t('home')}
                                </Link>
                                <Link 
                                    to="section1" 
                                    spy={true} 
                                    smooth={true} 
                                    className="nav-item text-medium"
                                >
                                    {t('Vivid_Wash')}
                                </Link>
                                <Link 
                                    to="section2" 
                                    spy={true} 
                                    smooth={true} 
                                    className="nav-item text-medium"
                                >
                                    {t('Vivid_Wash_Owner')}
                                </Link>
                                <div 
                                    onClick={() => navigate('/')}
                                    className="nav-item text-medium cursor-pointer"
                                >
                                    {t('Tailwind Trade')}
                                </div>
                            </div>
                            <button className="download-btn text-white font-medium rounded-full !rounded-button whitespace-nowrap hidden sm:block">
                                {t('download_text')}
                            </button>
                            <div className='menu-button' onClick={toggleMenu}>
                                <span className='mdi mdi-menu text-[20px]'></span>
                            </div>
                        </div>
                    </nav>
                    <div className="index-main" id='home'>
                        <div className="index-banner">
                            <div className="banner-text-bg">
                                <img src={vivid}   className="l-dark " alt="" />
                            </div>
                            <div className="banner-swiper ">
                                <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                autoplay={{ delay: 2000, disableOnInteraction: false }}
                                modules={[Autoplay]}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                >
                                    <SwiperSlide>
                                        <img src={slide} className="l-dark" alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={slide} className="l-dark" alt="" />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>

                    <div className="index-section index-m-video">
                        <div className="m-block-video">
                            <Player poster={video_poster}>
                                <source src={video_src} ></source>
                            </Player>
                        </div>
                    </div>
                    <div className="index-section" id='section1'>
                        <div className="block-conainer">
                            <div className="block-main">
                                <div className="block-panel">
                                    <div className="block-lang">
                                        <img src={serbia}   className="lang-icon" alt="" />
                                    </div>
                                    <div className="block-number">01</div>
                                    <div className="block-panel-items">
                                        <div className="block-item-title">
                                        {t('s1_title')}
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={aIcon1} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s1_text_1')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={aIcon2} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s1_text_2')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={aIcon3} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s1_text_3')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={aIcon4} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s1_text_4')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-download">
                                        <a href="#" className="btn btn-download">
                                        Vivid Wash {t('download')}
                                        </a>
                                    </div>
                                </div>
                                <div className="block-body">
                                    <div className="block-top">
                                    {t('s1_question')}
                                    </div>
                                    <div className="block-photo">
                                        <img src={car} alt="" />
                                    </div>
                                    <div className="block-footer">
                                        <div className="block-icon">
                                            <img src={phone1}   alt="" />
                                        </div>
                                        <div className="block-text">
                                        {t('s1_answer')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="index-section" id='section2'>
                        <div className="block-conainer">
                            <div className="block-main">
                                <div className="block-panel">
                                    <div className="block-lang">
                                        <img src={serbia}   className="lang-icon" alt="" />
                                    </div>
                                    <div className="block-number">02</div>
                                    <div className="block-panel-items">
                                        <div className="block-item-title">
                                            {t('s2_title')}
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={bIcon1} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s2_text_1')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={bIcon2} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s2_text_2')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={bIcon3} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s2_text_3')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={bIcon4} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s2_text_4')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={bIcon5} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s2_text_5')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={bIcon6}   alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s2_text_6')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-download">
                                        <a href="#" className="btn btn-download">
                                        Vivid Wash {t('download')}
                                        </a>
                                    </div>
                                </div>
                                <div className="block-body">
                                    <div className="block-top">
                                        {t('s2_question')}
                                    </div>
                                    <div className="block-photo">
                                        <img src={dataPic} alt="" />
                                    </div>
                                    <div className="block-footer">
                                        <div className="block-icon">
                                            <img src={phone2}   alt="" />
                                        </div>
                                        <div className="block-text">
                                            {t('s2_answer')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="index-section" id='section3'>
                        <div className="block-conainer">
                            <div className="block-main">
                                <div className="block-panel">
                                    <div className="block-lang">
                                        <img src={serbia}   className="lang-icon" alt="" />
                                    </div>
                                    <div className="block-number">03</div>
                                    <div className="block-panel-items">
                                        <div className="block-item-title">
                                            {t('s3_title')}
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={cIcon1} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s3_text_1')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={cIcon2} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s3_text_2')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={cIcon3} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s3_text_3')}
                                            </div>
                                        </div>
                                        <div className="block-item">
                                            <div className="block-item-icon">
                                                <img src={cIcon4} alt="" />
                                            </div>
                                            <div className="block-item-text">
                                            {t('s3_text_4')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-download">
                                        <a href="#" className="btn btn-download">
                                            Vivid Wash {t('download')}
                                        </a>
                                    </div>
                                </div>
                                <div className="block-body hidden lg:block">
                                    <div className="block-video">
                                        <Player poster={video_poster}>
                                            <source src={video_src} ></source>
                                        </Player>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="index-section">
                        <div className="section-header">
                            <div className="section-title">
                                1000+ {t('customerreviews')}
                            </div>
                            <div className="section-description">
                            {t('customerDesc')}
                            </div>
                            <div className="section-hor hor-top"></div>
                            <div className="section-hor hor-bottom"></div>
                            <div className="section-ver ver-left"></div>
                            <div className="section-ver ver-right"></div>
                        </div>
                        <div className="block-conainer">
                            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-6">
                                <ul className="reviews-list">
                                    <li className="reviews-li">
                                        <div className="reviews-card">
                                            <div className="mb-3">
                                                <ul className="list-none mb-0 text-amber-400 mb-2">
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="reviews-body">
                                                {t('reviews1')}
                                            </div>
                                            <div className="reviews-footer">
                                                <div className="reviews-item">
                                                    Belgrade
                                                </div>
                                                <div className="line"></div>
                                                <div className="reviews-item">
                                                    Marko
                                                </div>
                                                <div className="reviews-avatar">
                                                    <img src={avatar} className="reviews-avatar" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="reviews-li">
                                        <div className="reviews-card">
                                            <div className="mb-3">
                                                <ul className="list-none mb-0 text-amber-400 mb-2">
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="reviews-body">
                                                {t('reviews2')}
                                            </div>
                                            <div className="reviews-footer">
                                                <div className="reviews-item">
                                                    Belgrade
                                                </div>
                                                <div className="line"></div>
                                                <div className="reviews-item">
                                                    Marko
                                                </div>
                                                <div className="reviews-avatar">
                                                    <img src={avatar} className="reviews-avatar" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="reviews-list">
                                    <li className="reviews-li">
                                        <div className="reviews-card">
                                            <div className="mb-3">
                                                <ul className="list-none mb-0 text-amber-400 mb-2">
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="reviews-body">
                                                {t('reviews3')}
                                            </div>
                                            <div className="reviews-footer">
                                                <div className="reviews-item">
                                                    Belgrade
                                                </div>
                                                <div className="line"></div>
                                                <div className="reviews-item">
                                                    Marko
                                                </div>
                                                <div className="reviews-avatar">
                                                    <img src={avatar} className="reviews-avatar" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="reviews-li">
                                        <div className="reviews-card">
                                            <div className="mb-3">
                                                <ul className="list-none mb-0 text-amber-400 mb-2">
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="reviews-body">
                                            {t('reviews4')}
                                            </div>
                                            <div className="reviews-footer">
                                                <div className="reviews-item">
                                                    Belgrade
                                                </div>
                                                <div className="line"></div>
                                                <div className="reviews-item">
                                                    Marko
                                                </div>
                                                <div className="reviews-avatar">
                                                    <img src={avatar} className="reviews-avatar" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="reviews-list">
                                    <li className="reviews-li">
                                        <div className="reviews-card">
                                            <div className="mb-3">
                                                <ul className="list-none mb-0 text-amber-400 mb-2">
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="reviews-body">
                                                {t('reviews5')}
                                            </div>
                                            <div className="reviews-footer">
                                                <div className="reviews-item">
                                                    Belgrade
                                                </div>
                                                <div className="line"></div>
                                                <div className="reviews-item">
                                                    Marko
                                                </div>
                                                <div className="reviews-avatar">
                                                    <img src={avatar} className="reviews-avatar" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="reviews-li">
                                        <div className="reviews-card">
                                            <div className="mb-3">
                                                <ul className="list-none mb-0 text-amber-400 mb-2">
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="reviews-body">
                                                {t('reviews6')}
                                            </div>
                                            <div className="reviews-footer">
                                                <div className="reviews-item">
                                                    Belgrade
                                                </div>
                                                <div className="line"></div>
                                                <div className="reviews-item">
                                                    Marko
                                                </div>
                                                <div className="reviews-avatar">
                                                    <img src={avatar} className="reviews-avatar" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="index-footer">
                        <div className="container">
                            <div className="footer-top">
                                <div className="contact-link">
                                    <a href="#" className="link-us">
                                    {t('contact')} <span></span>
                                    </a>
                                </div>
                                <div className="footer-share">
                                    <a href="#" className="share-link">
                                        <img src={facebook}   className="share-icon" alt="" />
                                    </a>
                                    <a href="#" className="share-link">
                                        <img src={instagram}   className="share-icon" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="footer-row">
                                <div className="footer-logo">
                                    <img src={logo_dark}   className="l-dark " alt="" />
                                    <span>Altpay</span>
                                </div>
                                <div className="footer-link">
                                    <a href="#" className="link-item">
                                    {t('home')}
                                    </a>
                                    <a href="#" className="link-item">
                                    {t('Vivid_Wash')}
                                    </a>
                                    <a href="#" className="link-item">
                                    {t('Vivid_Wash_Owner')}
                                    </a>
                                </div>
                                <div className="footer-right">
                                    <span className='color-green'>
                                        Vivid Wash in Tailwind
                                    </span>
                                    <span className='color-gray'>©2023  Vivid Wash</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </>
    )
}

