import './App.scss';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './main/aboutus';
import './assets/css/tailwind.css'
import './assets/libs/@mdi/font/css/materialdesignicons.min.css'
import './assets/libs/@iconscout/unicons/css/line.css'
import './assets/libs/tobii/css/tobii.min.css'
import './assets/images/favicon.ico'
import BlogDetail from './main/blog-detail';
import Blogs from './main/blogs';
import ContactUs from './main/contactus';
import Index from './main';
import Services from './main/services';
import Team from './main/team';
import SerbiaIndex from './main/serbia-index';
import ScrollToTop from './component/Scroll-top';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

export default function App() {


  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter >
        <ScrollToTop />
        <Routes>
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/blog-detail" element={<BlogDetail />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/index" element={<Index />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/" element={<Index />} />
          <Route path="/vividwash" element={<SerbiaIndex />} />

        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  )
};